import './styles.css'
import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

function PdfView(props) {


	pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

	const [numPages, setNumPages] = useState(0); // eslint-disable-line no-unused-vars
	const [pageNumber, setPageNumber] = useState(1); // eslint-disable-line no-unused-vars

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const downloadArticle = () => {
		const link = document.createElement('a');
		const path = `/image${props.file}`
		link.href = path;
		link.download = path;
		link.click();
	};

	return (
		<>
			<div className='pdf-div'>
				<Document file={`/image${props.file}`} onClick={downloadArticle} onLoadSuccess={onDocumentLoadSuccess}>
					<Page pageNumber={pageNumber} />
				</Document>
			</div>
		</>

	)
}

export default PdfView