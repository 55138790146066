import './styles.css'
import { easeInOut, motion as m } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom';
import Item from '../../components/Item'
import PdfView from '../../components/Pdfview';
import artData from '../../assets/ArtCollection.json'
import watchData from '../../assets/WatchCollection.json'
import aboutData from '../../assets/aboutData.json'


function Gallery(props) {
	const { id } = useParams();
	let data;
	if (window.location.pathname.includes('/Watches/')) {
		data = watchData;
	} else if (window.location.pathname.includes('/Arts')) {
		data = artData;
	} else {
		data = aboutData;
	}
	const pathArticle = window.location.pathname.includes('/Article');
	const pathAbout = window.location.pathname.includes('/About');
	let dataToUse;
	if (!pathAbout) {
		dataToUse = data.find(e => e.id === id);
	}
	const artwork = dataToUse ? dataToUse.artwork : [];
	const article = dataToUse ? dataToUse.link.article : [];
	const aboutDataUsed = props.pdf ? props.pdf.article : [];
	const navigate = useNavigate();
	const itemVariants = {
		hidden: { opacity: 0, y: -30 },
		show: { opacity: 1, y: 0 },
	};

	const containerVariants = {
		hidden: { opacity: 0 },
		show: {
			opacity: 1,
			transition: {
				staggerChildren: 0.1,
			}
		}
	};

	return (
		<>
			<m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: easeInOut }} className='gallery-container'>
				<button className="close-page" onClick={() => navigate(-1)}> Close </button>
				<m.div variants={containerVariants} initial="hidden" animate="show" className='gallery-animation'>
					{pathAbout && aboutDataUsed ? (
						aboutDataUsed.map((item, index) => (
							<m.div variants={itemVariants} key={index} className='gallery-items'>
								<PdfView file={item} key={index} />
							</m.div>
						))
					) : pathArticle && article ? (
						article.map((item, index) => (
							<m.div variants={itemVariants} key={index} className='gallery-items'>
								<PdfView file={item} key={index} />
							</m.div>
						))
					) : (
						artwork.map((item, index) => (
							<m.div key={index} variants={itemVariants} className='gallery-items'>
								<Item image={item[0]} index={index} artworkName={dataToUse.id} linked={props.link} />
							</m.div>
						))
					)}
				</m.div>
			</m.main>
		</>
	);
}


export default Gallery