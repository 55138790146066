import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Arts from './pages/Art';
import Error from './pages/Error';
import Detail from './pages/Detail';
import Artwork from './components/Artwork';
import About from './pages/About/index'
import Gallery from './pages/Gallery'
import artData from './assets/ArtCollection.json'
import watchData from './assets/WatchCollection.json'
import aboutData from './assets/aboutData.json'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
        <Route path='/About' element={<About />} />
        <Route path='/About/Article' element={<Gallery pdf={aboutData} />} />
        <Route path='/Arts' element={<Arts key='art' link='Arts' data={artData} title={'Art Collections'} titleMobile={'Arts'} />} />
        <Route path='/Arts/Detail/:id' element={<Detail link='Arts' />} />
        <Route path='/Arts/Detail/:id/Gallery' element={<Gallery link='Arts' />} />
        <Route path='/Arts/Detail/:id/Artwork/:artworkId' element={<Artwork link='Arts' />} />
        <Route path='/Arts/Detail/:id/Article' element={<Gallery link='Arts' />} />
        <Route path='/Watches' element={<Arts key='watch' link='Watches' data={watchData} textColor={'#127E80'} title={'Watch Collections'} titleMobile={'Watches'} />} />
        <Route path='/Watches/Detail/:id' element={<Detail link='Watches' />} />
        <Route path='/Watches/Detail/:id/Artwork/:artworkId' element={<Artwork link='Watches' />} />
        <Route path='/Watches/Detail/:id/Gallery' element={<Gallery link='Watches' />} />
        <Route path='/Watches/Detail/:id/Article' element={<Gallery link='Watches' />} />
        <Route path='/*' element={<Error />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
