import './styles.css'
import { useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { easeInOut, motion as m } from 'framer-motion';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import DOMPurify from 'dompurify';
import useEmblaCarousel from 'embla-carousel-react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import watchData from '../../assets/WatchCollection.json'
import artData from '../../assets/ArtCollection.json'
import arrowOut from '../../assets/svg/outward.svg'


function Detail(props) {

    const [emblaRef] = useEmblaCarousel(
        { loop: true, skipSnaps: true },
        [WheelGesturesPlugin({ forceWheelAxis: 'x' })]
    );

    const { id } = useParams();

    const data = window.location.pathname.includes('/Watches/') ? watchData : artData;
    const dataToUse = data.find(e => e.id === id)
    const artwork = dataToUse.artwork
    const article = dataToUse.link.article
    const [open, setOpen] = useState(false)
    const isMobile = useMediaQuery('(max-width:800px)');
    const navigate = useNavigate();

    return (
        <>
            {
                isMobile ? (
                    <>
                        <Header />
                        <m.main
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.75, ease: easeInOut }}
                            className='detail-container' >
                            <section className="exhi-title-btn">
                                <h1 className="exhi-title">{dataToUse.title}</h1>
                                <div className="exhi-btn-year">
                                    <div className={'exhi-btn'}><div className="svg-icon"></div>
                                    </div>
                                    <div className="exhi-year">{dataToUse.year}</div>
                                </div>
                            </section>
                            <section className="overflow-wrapper" ref={emblaRef} data-axis={'x'}>
                                <div className="gallery-exhibition" >
                                    {dataToUse.image.map((image, index) => (
                                        <div className='gallery-slide' key={index}>
                                            {image.includes('.mp4') ?
                                                (<video src={`/image${image}`} type="video/mp4" autoPlay loop muted></video>)
                                                : (<img src={`/image${image}`} alt={index} key={index} loading='Eager' rel='preload' ></img>)}
                                        </div>))}
                                </div>
                            </section>
                            <section className='container-exhibition'>
                                {artwork.length === 0 ? '' : (<NavLink className="link-viewartwork" to={`/${props.link}/Detail/${dataToUse.id}/Gallery`}><div className="view-artwork">View Artworks</div></NavLink>)}
                                <div className='exhi-text-link'>
                                    <div className='exhi-text '>
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataToUse.Desc) }} />
                                    </div>
                                    <div className="exhi-links">
                                        {article.length === 0 ? '' : (<NavLink to={`/${props.link}/Detail/${dataToUse.id}/Article`}><div className="exhi-link">View Article <img src={arrowOut} alt='arrow icon' /></div></NavLink>)}
                                        < div className="exhi-link"><a href={dataToUse.link.insta}>Instagram</a><img src={arrowOut} alt='arrow icon' /></div>
                                    </div>
                                </div>
                            </section>
                        </m.main>
                        <Footer />
                    </>
                ) : (
                    <m.main
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.75, ease: easeInOut }}
                        className={`detail-container ${open ? 'maxdvh' : ''}`}>
                        <section className="overflow-wrapper" id='container-btn' ref={emblaRef} data-axis={'x'}>
                            <div className="gallery-exhibition">
                                {artwork.length === 0 ? dataToUse.image.map((image, index) => (
                                    <div className='gallery-slide' key={index}>
                                        {image.includes('.mp4') ?
                                            (<video src={`/image${image}`} type="video/mp4" autoPlay loop muted></video>)
                                            : (<img src={`/image${image}`} alt={index} key={index}></img>)}
                                    </div>
                                )) : dataToUse.image.map((image, index) => (
                                    <div className='gallery-slide' key={index}>
                                        {image.includes('.mp4') ?
                                            (<video src={`/image${image}`} type="video/mp4" autoPlay loop muted></video>)
                                            : (<img src={`/image${image}`} alt={index} key={index}></img>)}
                                    </div>))}
                            </div>
                            <span className='btn-close' onClick={() => navigate(-1)}>Close</span>
                        </section>
                        <section className={`container-exhibition ${open ? 'detail-active' : 'detail-inactive'}`}>
                            <div className="exhi-title-btn">
                                <h1 className="exhi-title">{dataToUse.title}</h1>
                                <div className="exhi-btn-year">
                                    {artwork.length === 0 ? '' : (<NavLink className="link-viewartwork" to={`/${props.link}/Detail/${dataToUse.id}/Gallery`}><div className="view-artwork">View Artworks</div></NavLink>)}
                                    <div onClick={() => { setOpen(!open) }} className={`exhi-btn ${open ? 'btn-active' : 'btn-inactive'}`}><div className="svg-icon"></div>
                                    </div>
                                    <div className="exhi-year">{dataToUse.year}</div>
                                </div>
                            </div>
                            <div className='exhi-text-link'>
                                <div className={`exhi-text ${open ? 'text-active' : 'text-inactive'}`}>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dataToUse.Desc) }} />
                                </div>
                                <div className="exhi-links">
                                    {article.length === 0 ? '' : (<NavLink to={`/${props.link}/Detail/${dataToUse.id}/Article`}><div className="exhi-link">View Article <img src={arrowOut} alt='arrow icon' /></div></NavLink>)}
                                    <div className="exhi-link"><a href={dataToUse.link.insta}>Instagram</a><img src={arrowOut} alt='arrow icon' /></div>
                                </div>
                            </div>
                        </section>
                    </m.main>)
            }

        </>
    )
}

export default Detail;