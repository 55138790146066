import './styles.css';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import artData from '../../assets/ArtCollection.json'
import watchData from '../../assets/WatchCollection.json'
import arrowLeft from '../../assets/svg/leftarrow.svg'
import arrowRight from '../../assets/svg/choose arrow.svg'


function Artwork() {

  const { id, artworkId } = useParams();
  const data = window.location.pathname.includes('/Watches/') ? watchData : artData;
  const dataToUse = data.find(e => e.id === id)
  const artwork = dataToUse.artwork
  const navigate = useNavigate();
  const [startX, setStartX] = useState(null);
  const [slideIndex, setSlideIndex] = useState(parseInt(artworkId, 10) || 0);

  const prevSlide = () => {
    const isFirstSlide = slideIndex === 0;
    const newIndex = isFirstSlide ? artwork.length - 1 : slideIndex - 1;
    setSlideIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = slideIndex === artwork.length - 1;
    const newIndex = isLastSlide ? 0 : slideIndex + 1;
    setSlideIndex(newIndex);
  };


  const handleSwipe = (event) => {
    let diffX;
    if (event.type === 'touchstart') {
      setStartX(event.touches[0].clientX);
    } else if (event.type === 'touchmove') {
      diffX = event.touches[0].clientX - startX;
    } else if (event.type === 'touchend') {
      diffX = startX - event.changedTouches[0].clientX;
      if (diffX > 50) {
        if (slideIndex <= artwork.length - 1) {
          nextSlide();
        }
      } else if (diffX < -50) {
        if (slideIndex >= 0) {
          prevSlide();
        }
      }
    }
  };

  return (
    <>
      <main className="carousel-container">
        <button className="close-page" onClick={() => navigate(-1)}> Close </button>
        <button className="carousel-arrowl carousel-arrow" onClick={prevSlide}><img src={arrowLeft} alt='arrow icon' /></button >
        <div className="artwork-container">
          <div className={`artwork-picture`} onTouchStart={handleSwipe} onTouchMove={handleSwipe} onTouchEnd={handleSwipe}>
            <img className={`${slideIndex == null ? '' : 'active'}`} src={`/image${artwork[slideIndex][1]}`} alt={artwork[slideIndex[2]]} key={slideIndex} />
          </div>
          <div className="artwork-text">
            <div className="artwork-desc">
              <div className="artwork-title">{artwork[slideIndex][2]}</div>
              <div className="tech-size">
                <div className="artwork-tech">{artwork[slideIndex][3]}</div>
                <div className="artwork-size">{artwork[slideIndex][4]}</div>
              </div>
              <div className="artwork-collection">{artwork[slideIndex][5]}</div>
            </div>
            <div className="artwork-number">{`${slideIndex + 1} / ${artwork.length}`}</div>
          </div>
        </div>
        <button className="carousel-arrowr carousel-arrow" onClick={nextSlide}><img src={arrowRight} alt='arrow icon' /></button >
      </main>
    </>
  )
}
export default Artwork;