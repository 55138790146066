import './styles.css'
import { useMediaQuery } from '@react-hook/media-query';
import { easeInOut, motion as m } from 'framer-motion'
import { NavLink } from 'react-router-dom';
import Columned from 'react-columned';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Card from '../../components/Card';

function Arts(props) {

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
        });
    }

    const pourcentage = '100%'
    const colorText = props.textColor
    const isMobile = useMediaQuery('(max-width: 800px)');

    const itemVariants = {
        hidden: { opacity: 0, y: -30 },
        show: { opacity: 1, y: 0 },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            }
        }
    }

    return (
        <>
            <Header />
            {isMobile ? (<m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: easeInOut }} className="main-container-card">
                < h1 className="container-card-title" style={{ color: colorText }
                } > {props.titleMobile}</h1 >
                <m.div variants={containerVariants}
                    initial="hidden"
                    animate="show"
                    className="container-all-card">
                    {props.data.map((item) => (
                        <m.div
                            variants={itemVariants}
                            key={item.id}>
                            <NavLink className='card-link' onClick={scrollTop} to={`/${props.link}/Detail/${item.id}`}>
                                <Card
                                    data={item}
                                    width={{ width: pourcentage }}
                                    color={props.textColor}
                                />
                            </NavLink>
                        </m.div>
                    ))}
                </m.div>
            </m.main >) : (
                (<m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: easeInOut }} className="main-container-card">
                    <h1 className="container-card-title" style={{ color: colorText }} >{props.title}</h1>
                    <m.div variants={containerVariants}
                        initial="hidden"
                        animate="show"
                        className="container-all-card">
                        <Columned
                            className="Columned"
                            columns={{ "1000": 3 }}>
                            {props.data.map((item) => (
                                <m.div
                                    variants={itemVariants}
                                    key={item.id}>
                                    <NavLink className='card-link' to={`/${props.link}/Detail/${item.id}`}>
                                        <Card
                                            data={item}
                                            width={{ width: pourcentage }}
                                            color={props.textColor}
                                        />
                                    </NavLink>
                                </m.div>
                            ))}
                        </Columned>
                    </m.div>
                </m.main >)
            )}
            < Footer />
        </>
    )
}

export default Arts;