import './styles.css'
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/Dr-Studio_Logo_Footer.png'
import instaIcon from '../../assets/svg/insta.svg'
import mailIcon from '../../assets/svg/mailsvgicon.svg'
import whatIcon from '../../assets/svg/Whatappssvgicon.svg'
import facebookIcon from '../../assets/svg/facebooksvgicon.svg'


function Footer() {

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior:'smooth'
        });
    }
    return (
        <>
            <footer className='footer'>
                <div className="footer-container">
                    <div className="footer-logo">
                        <img src={Logo} alt='dr-studio logo black' loading='lazy'></img>
                    </div>
                    <div >
                        <ul className="footer-links">
                            <li><NavLink onClick={scrollTop} className='footer-link' to="/Home" >Home</NavLink></li>
                            <li><NavLink onClick={scrollTop} className='footer-link' to="/Arts" >Arts</NavLink></li>
                            <li><NavLink onClick={scrollTop} className='footer-link' to="/Watches" >Watches</NavLink></li>
                            <li><NavLink onClick={scrollTop} className='footer-link' to="/About" >About & Contact</NavLink></li>
                        </ul>
                    </div>
                    <div className="contact-link">
                        <p className='contact-text'>Contact us</p>
                        <div className="social-links">
                            <a href="mailto:davidrrd@gmail.com" target="_blank" rel='noreferrer'><div className="social-link"><img src={mailIcon} alt='Mail icon' /></div></a>
                            <a href="https://www.instagram.com/david.rodriguez_artist" target="_blank" rel="noreferrer"><div className="social-link"><img src={instaIcon} alt='insta icon' /></div></a>
                            <a href='https://www.facebook.com/artdavidrodriguez' target="_blank" rel='noreferrer'><div className="social-link"><img src={facebookIcon} alt='fb icon' /></div></a>
                            <a href='https://wa.me/+66659967649' target='_blank' rel='noreferrer'><div className="social-link"><img src={whatIcon} alt='whats icon' /></div></a>
                        </div>
                    </div>
                </div>
                <div className="copyright">© 2023 David Rodriguez</div>
            </footer>
        </>
    )
}

export default Footer;