import './styles.css'
import { useMediaQuery } from '@react-hook/media-query';
import { easeInOut, motion as m } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import artistSchema from '../../assets/artistSchema.json'
import PictureDavid from '../../assets/images/Picture_About.webp'
import PictureDavid8 from '../../assets/images/Picture_About_800.webp'
import PictureContact from '../../assets/images/Picture_contact.webp'
import PictureContact8 from '../../assets/images/Picture_contact_800.png'
import instaIcon from '../../assets/svg/instawhite.svg'
import faceIcon from '../../assets/svg/facebookwhite.svg'
import whatIcon from '../../assets/svg/Whatappswhite.svg'



function About() {

    const isMobile = useMediaQuery('(max-width:800px)')

    return (
        <>
            <Header />
            <script type="application/ld+json">
                {JSON.stringify(artistSchema)}
            </script>
            <m.main initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: easeInOut }}
                className="container-about">
                <section className="section-about">
                    <h1 className="title-about">RODRIGUEZ</h1>
                    <img
                        src={isMobile ? PictureDavid8 : PictureDavid}
                        alt="Pictures of David"
                        className="picture-about"
                    />
                    <p className="desc-about">David Rodriguez's artistic journey is a vibrant fusion of humor, boundary-pushing exploration, and thought-provoking introspection. Starting as a biscuit salesman in France, David found inspiration in the ornate design of biscuit tins. Playfully challenging traditional norms, he transformed these tins by painting intricate, miniature pornographic images, questioning the subliminal messages embedded in the language of biscuit advertising (<i>Salon de Montrouge, 2014</i> ).</p>
                    <p className="desc-small-about">From that point onwards, his trajectory is marked by a constant quest for unconventional expression within traditional art forms. Collecting vintage images and objects, David explores with deep curiosity the interplay between art history (<i>Florilegia Painting 2021</i> ), societal norms (<i>Feeling lonely, 2016</i> ), and personal identity (<i>Self, 2018</i> ). He also, delve into the societal representation of the human body, transitioning to themes of voyeurism and emancipation, and a captivating fascination with the female form.<br />
                        His artistic pursuits extend beyond traditional painting. Known for his skill in watch restoration and design, he is a respected collector in Shanghai. His passion for preserving ancient crafts and objects enriches not only his artistic practice but also but also forges connections with the cultural heritage of the cities he calls home. <br />
                        David Rodriguez, born in 1984, is a Colombian-French visual artist currently living in Thailand.
                    </p>
                    <NavLink to='/About/Article'>
                        <div className="view-article">View Article</div></NavLink>
                </section>
                <section className="section-contact">
                    <div className="picture-title">
                        <img className="img1" src={isMobile ? PictureContact8 : PictureContact} alt='Contact Media'></img>
                        <h2 className="title-contact">Contact</h2></div>
                    <div className="info-contact">
                        <p className="text-contact">Let's Connect! Whether you have questions, inquiries, or feedback, I'm here to chat. Simply fill out the form below to get in touch.</p>
                        <div className="media-mail">
                            <p className='title-info info-email'>(Email)</p>
                            <p>davidrrd@gmail.com</p>
                        </div>
                        <div className="separateur"></div>
                        <div className="media-mail">
                            <p className='title-info info-social'>(Social Media)</p>
                            <div className="btn-social">
                                <a href="https://www.instagram.com/david.rodriguez_artist" target="_blank" rel='noreferrer'><div><img src={instaIcon} alt="insta icon" />
                                </div></a>
                                <a href='https://www.facebook.com/artdavidrodriguez' target="_blank" rel='noreferrer'><div><img src={faceIcon} alt="facebook icon" /></div></a>
                                <a href='https://wa.me/+66659967649' target='_blank' rel='noreferrer'><div><img src={whatIcon} alt="whats-app icon" /></div></a>
                            </div>
                        </div>
                        <div className="separateur"></div>
                        <Contact />
                    </div>
                </section>
            </m.main>
            <Footer />
        </>
    )
}

export default About;