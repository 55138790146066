import './styles.css'
import { useForm, ValidationError } from "@formspree/react";

function Contact() {

    const [state, handleSubmit] = useForm('xayrevjp');
    return (
        <div className="contact-form">
            <h3 className="contact-text"> General Enquiry</h3>
            {(state.succeeded) ? (
                <div className="success">
                    <p className='thanks'>Thank you !</p>
                    <p className='msg-success'>Your message has been successfully submitted. I will respond as soon as possible!</p>
                </div >
            ) : (
                <form className="form" onSubmit={handleSubmit} action="https://formspree.io/f/xayrevjp" method="POST" >
                    <div className="form-style name">
                        <label htmlFor="name">Name </label>
                        <input type="text" id="name" name="name" placeholder='Ex. John Website' required />
                        <ValidationError prefix="Name" field="name" errors={state.errors} />
                    </div>
                    <div className="form-style telephone">
                        <label htmlFor="telephone">Mobile</label>
                        <input type="text" id="telephone" name="telephone" placeholder='+66 12 345 6789' required />
                        <ValidationError prefix="telephone" field="telephone" errors={state.errors} />
                    </div>
                    <div className="form-style email">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder='Email@Email.com' required />
                        <ValidationError className="validation" prefix="Email" field="email" errors={state.errors} />
                    </div>
                    <div className="form-style text">
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" rows="2" cols="15" placeholder='Text your message here' required></textarea>
                        <ValidationError prefix="Message" field="message" errors={state.errors} />
                    </div>
                    <input className="form-button" type="submit" value="Submit Now" />
                </form>
            )}</div>
    )
}

export default Contact;