import './styles.css'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import Logo from '../../assets/images/Dr-Studio_Logo.png'
import LogoBlack from '../../assets/images/LogoMenuBlack.png'
import LogoWhite from '../../assets/images/LogoMenuWhite.png'
import InstaIcon from '../../assets/svg/InstaIconMedia.svg'
import FaceIcon from '../../assets/svg/facebookIconmedia.svg'
import WhatIcon from '../../assets/svg/WhatappsIconmedia.svg'

function Header() {

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const isMobile = useMediaQuery('(max-width: 800px)');

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);

        document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
    };
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
        });
    }

    return (
        <>
            <header className={`header ${isMenuOpen ? 'header-black' : ''}`} >
                <div className="logo">
                    <NavLink to="/" ><img src={
                        isMobile
                            ? isMenuOpen
                                ? LogoBlack
                                : LogoWhite
                            : Logo
                    } alt="dr-studio Logo" className='logo' /></NavLink>
                </div>
                <nav >
                    <div className={`menu-link ${isMenuOpen ? 'btn-close' : ''}`} onClick={toggleMenu}>
                        <div className={`menu-bar ${isMenuOpen ? 'white' : ''}`}></div>
                        <div className={`menu-bar ${isMenuOpen ? 'white' : ''}`}></div>
                        <div className={`menu-bar ${isMenuOpen ? 'white' : ''}`}></div>
                    </div>
                    <ul
                        className={`nav-links ${isMenuOpen ? 'menu-open' : ''}`}>
                        <li>
                            <NavLink onClick={() => {
                                if (isMobile) {
                                    toggleMenu();
                                }
                                scrollTop();
                            }} className='link' to="/Home" >Home</NavLink></li>
                        <li> <NavLink onClick={() => {
                            if (isMobile) {
                                toggleMenu();
                            }
                            scrollTop();
                        }} className='link' to="/Arts" >Arts</NavLink></li>
                        <li> <NavLink onClick={() => {
                            if (isMobile) {
                                toggleMenu();
                            }
                            scrollTop();
                        }} className='link' to="/Watches" >Watches</NavLink></li>
                        <li> <NavLink onClick={() => {
                            if (isMobile) {
                                toggleMenu();
                            }
                            scrollTop();
                        }} className='link' to="/About" >About & Contact</NavLink></li>
                        <div className="social-link-media">
                            <a className="link-media" href='https://www.instagram.com/david.rodriguez_artist/' target='_blank' rel='noreferrer'>
                                <img src={InstaIcon} alt="instagram icon" /></a>
                            <a className="link-media" href='https://www.facebook.com/artdavidrodriguez' target='_blank' rel='noreferrer'>
                                <img src={FaceIcon} alt="facebook icon" /></a>
                            <a className="link-media" href='https://wa.me/+66659967649' target='_blank' rel='noreferrer'>
                                <img src={WhatIcon} alt="whatsapp icon" /></a>
                        </div>
                    </ul>

                </nav>
            </header>
        </>
    )
}

export default Header;