import './styles.css';
import { NavLink } from 'react-router-dom';
import { motion as m, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import Card from '../Card';
import arrowOut from '../../assets/svg/outwardsvgicon.svg'
import arrowLeft from '../../assets/svg/leftarrow.svg';
import arrowRight from '../../assets/svg/choose arrow.svg';


function Project(props) {

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
        });
    }

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            controls.start('show');
        }
    }, [controls, inView]);


    const slideLeft = () => {
        var slider = document.querySelector(`#${props.id}`, 'card-container');
        slider.scrollLeft = slider.scrollLeft - 350;
    }
    const slideRight = () => {
        var slider = document.querySelector(`#${props.id}`, 'card-container');
        slider.scrollLeft = slider.scrollLeft + 350;
    }

    const itemVariants = {
        hidden: { opacity: 0, x: 30 },
        show: { opacity: 1, x: 0 },
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
            }
        }
    }

    return (
        <section className="project-container" >
            <div className="project-title-arrow">
                <div className="title-view">
                    <h1 className="project-title" style={props.color}>{props.title}</h1>
                    <NavLink to={`/${props.id}`} onClick={scrollTop} className="btn-viewall">View All<img src={arrowOut} alt='svg view-icon' /></NavLink>
                </div>
                <div className="gallery-arrow">
                    <button className="arrow left-arrow" onClick={slideLeft} > <img src={arrowLeft} alt='svg left-icon' /></button>
                    <button className="arrow right-arrow" onClick={slideRight} ><img src={arrowRight} alt='svg right-icon' /></button>
                </div>
            </div>
            <div className="separator"></div>
            <div className="project-gallery" >
                <m.div variants={containerVariants}
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    className="card-container" id={props.id}>
                    {props.data.map((data, index) => (
                        <m.div
                            key={index}
                            variants={itemVariants}>
                            <NavLink className='card-link' onClick={scrollTop} to={`/${props.id}/Detail/${data.id}`}>
                                <Card
                                    desc={props.styleDesc}
                                    Year={props.styleYear}
                                    style={props.color}
                                    data={data}
                                /></NavLink>
                        </m.div>
                    ))}
                </m.div>
            </div>
        </section>
    );
}

export default Project;
