import './styles.css'
import { Link } from 'react-router-dom'

function Item({ image, index, artworkName, linked }) {
	return (
		<div className='gallery-item' >
			<Link className='card-link' to={`/${linked}/Detail/${artworkName}/Artwork/${index}`}>
				<img loading='Eager' src={`/image${image}`} alt={`${artworkName}-${index}`} />
			</Link>
		</div>
	)
}

export default Item