import './styles.css'
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import { motion as m, easeInOut } from 'framer-motion';
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import image1 from '../../assets/images/Error-1.webp'
import image2 from '../../assets/images/Error-2.webp'
import image3 from '../../assets/images/Error-3.webp'
import image from '../../assets/images/Picture_contact_800.png'

function Error() {

    const isMobile = useMediaQuery('(max-width:800px)')

    return (
        <>
            <Header />
            <m.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.75, ease: easeInOut }}
                className="error-container">
                <img src={isMobile ? image : image1} alt="Error" className="error-img" />
                <img src={image2} alt="Error" className="error-img" />
                <img src={image3} alt="Error" className="error-img" />
                <div className="error-txt-btn">
                    <div className="error-number">404</div>
                    <div className="error-txt">Page not found!</div>
                    <NavLink to="/Home" className="error-btn">Back to home</NavLink>
                </div>
            </m.main>
            <Footer />
        </>
    )
}

export default Error;