import './styles.css'

function Card(props) {

    const colorText = props.color

    return (
        <div id='card' key={props.index} >
            <div className="container-card">
                <div className="card-img">
                    <img src={`/image${props.data.mainImage}`} alt={`Exhibition-${props.data.id}`}></img>
                </div>
                <div className="card-title-year">
                    <div className="card-title-desc">
                        {/* <div className="card-title" >{props.data.title}</div> */}
                        <div className="card-desc" style={{ fontSize: `${props.desc}px` }}>{props.data.shortDesc}</div>
                    </div>
                    <div className="card-year" style={{ color: colorText, fontSize: `${props.year}px` }}>{props.data.year}</div>

                </div>
            </div>
        </div>
    )
}

export default Card;