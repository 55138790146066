import './styles.css';
import { useState, useEffect, useRef } from 'react';
import { easeInOut, motion as m } from 'framer-motion';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Project from '../../components/Project';
import artData from '../../assets/ArtCollection.json'
import watchData from '../../assets/WatchCollection.json'
import image1 from '../../assets/images/PictureDelay1.webp'
import image2 from '../../assets/images/PictureDelay2.webp'
import image3 from '../../assets/images/PictureDelay3.webp'


function Home() {


  const timerRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('image-in');

  const images = [image1, image2, image3];

  const nextSlide = () => {

    const isLastSlide = slideIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : slideIndex + 1;
    setAnimationClass('image-out');

    setTimeout(() => {
      setSlideIndex(newIndex);
      setAnimationClass('image-in');
    }, 800);
  }

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      nextSlide();
    }, 5000);
  });



  return (
    <div>
      <Header />
      <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: easeInOut }}>
        <div className="hero-container">
          <div className="hero-image">
            <img src={images[slideIndex]} onClick={nextSlide} alt='hero' className={`hero-image ${animationClass}`} />
          </div>
          <div className="hero-text">
            Provocative Fusion of Tradition and Rebellion.<br />
            Exploring societal representation, personal identity, and the interplay between art history and cultural heritage.<br />
            <span>David Rodriguez cordially invites you to explore his captivating artworks and exhibitions showcased on his website.</span></div>
        </div>
        <main className="main-container">
          {/* <h1 className='main-title'>PROJECT</h1> */}
          <div className="arts-container">
            <Project
              id={'Arts'}
              title='Arts'
              data={artData}
              styleDesc={16}
              styleYear={28} />
          </div>
          <div className="watches-container">
            <Project
              id={'Watches'}
              title='Watches'
              data={watchData}
              color={{ color: '#127E80' }} />
          </div>
        </main>
      </m.div>
      <Footer />
    </div>
  );
}

export default Home;
